import { config } from 'moduleAlias/config';

// === OP === //

export const facetsOP = [
	{
		slug: 'all',
		searchParameter: 'nl',
		contentType: null,
		eventActionName: 'ondernemersplein',
	},
	{
		slug: 'forum',
		searchParameter: 'forum',
		contentType: null,
		eventActionName: 'forum',
	},
] as const;

export type FacetSlugOP = typeof facetsOP[number]['slug'];
export type FacetSearchParameterOP = typeof facetsOP[number]['searchParameter'];

export const isFacetSlugOP = (string: string): string is FacetSlugOP =>
	facetsOP.some(({ slug }) => slug === string);

// === BGOV === //

export const facetsBGOV = [
	{
		slug: 'all',
		searchParameter: 'en',
		contentType: null,
		eventActionName: 'default',
	},
	{
		slug: 'regulations',
		searchParameter: 'en',
		contentType: 'Regulation',
		eventActionName: 'Regulation',
	},
	{
		slug: 'subsidies',
		searchParameter: 'en',
		contentType: 'Subsidy',
		eventActionName: 'Subsidy',
	},
	{
		slug: 'amendments',
		searchParameter: 'en',
		contentType: 'Amendment',
		eventActionName: 'Amendment',
	},
	{
		slug: 'forum',
		searchParameter: 'forum',
		contentType: null,
		eventActionName: 'forum',
	},
] as const;

export type FacetSlugBGOV = typeof facetsBGOV[number]['slug'];
export type FacetSearchParameterBGOV =
	typeof facetsBGOV[number]['searchParameter'];

const projectFacetsMapping = {
	op: facetsOP,
	bgov: facetsBGOV,
} as const;

export const getProjectFacets = () => {
	if (!(config.project in projectFacetsMapping))
		throw new Error(
			`Could not look up possible facets for project ${config.project}`
		);

	return projectFacetsMapping[config.project];
};

export type FacetSlug = FacetSlugBGOV | FacetSlugOP;
