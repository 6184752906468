import { config } from 'moduleAlias/config';

import { compilePathname } from '../../helpers/compilePathname';
import { getProjectSearchRoutes } from '../config/searchRoutes.config';
import { SearchRoutesParams, SearchState } from '../types/searchParams.types';

/**
 * Return a string to be used as a URL, based on the term, facet and page.
 * If no term is given, returns the default search-path (e.g. '/search/' for BGOV).
 */
export const getSearchURL = (state: SearchState): string => {
	const projectSearchRoutes = getProjectSearchRoutes();

	// Determine searchRoute on facet. If facet is 'all' do not show it in the url, since it is the fallback facet.
	const searchRoute =
		state.facetSlug === 'all' ? projectSearchRoutes[2] : projectSearchRoutes[0];

	return state.term.length > 0
		? compilePathname<SearchRoutesParams>(searchRoute, {
				facetSlug: state.facetSlug,
				term: state.term,
				page: String(state.page),
		  })
		: config.searchPath;
};
