'use client';

import { getSearchURL } from '@/globals/dataCollector/searchDataCollector/functions/getSearchURL';
import { SearchState } from '@/globals/dataCollector/searchDataCollector/types/searchParams.types';
import {
	Dispatch,
	SetStateAction,
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';

export type SearchHistory = Array<SearchState>;

export const searchHistoryContext = createContext<
	[SearchHistory, Dispatch<SetStateAction<SearchHistory>>] | undefined
>(undefined);

export const SearchHistoryProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const searchHistoryState = useState<SearchHistory>([]);
	return (
		<searchHistoryContext.Provider value={searchHistoryState}>
			{children}
		</searchHistoryContext.Provider>
	);
};

export const useSearchHistory = () => {
	const searchHistoryState = useContext(searchHistoryContext);
	if (!searchHistoryState) {
		throw new Error(
			'useGetSearchHistory must be used within SearchHistoryProvider'
		);
	}
	const [searchHistory] = searchHistoryState;
	return searchHistory;
};

export const useLastSearchURL = () => {
	const searchHistory = useSearchHistory();

	const lastSearchState = searchHistory.at(-1);

	if (lastSearchState == null) return undefined;

	return getSearchURL(lastSearchState);
};

const useUpdateSearchHistory = (searchState: SearchState) => {
	const searchHistoryState = useContext(searchHistoryContext);
	if (!searchHistoryState) {
		throw new Error(
			'useUpdateSearchHistory must be used within SearchHistoryProvider'
		);
	}
	const [, setSearchHistory] = searchHistoryState;

	useEffect(() => {
		if (searchState.term === '') return;

		setSearchHistory((searchHistory) => {
			const lastSearchState = searchHistory.at(-1);

			if (lastSearchState == null) return [...searchHistory, searchState];

			if (getSearchURL(lastSearchState) === getSearchURL(searchState))
				return searchHistory;

			return [...searchHistory, searchState];
		});
	}, [setSearchHistory, searchState]);
};

export const UpdateSearchHistory = ({
	searchState,
}: {
	searchState: SearchState;
}) => {
	useUpdateSearchHistory(searchState);

	return null;
};
